import ReactDOM from "react-dom/client";
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { PageHome } from "./app";

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
	<HttpsRedirect>
		<Router>
	        <Routes>
	          <Route path="*" element={ <PageHome/> } />
	        </Routes>
	    </Router>
	</HttpsRedirect>
);
