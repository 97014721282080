import React from "react";
import { StrictMode } from "react";
import "./styles.css";

function Home() {
  return (
	  <div className="content">
		  <h1>Hello!</h1>
		  <h2>
			  <p>
				  We are a group of creative and product professionals. Currently working on <a href="https://expansionbeyond.com" target="_blank">Expansion Beyond</a>.
				  Previously <a href="https://connector.im" target="_blank">Connector</a>.
			  </p>
			  <p><a href="mailto:hello@pm.email">Get in touch</a></p>
		  </h2>
	  </div>
  );
}

export class PageHome extends React.Component {
	render() {
		return (
			<StrictMode>
				<Home />
			</StrictMode>
		);
	}
}
